var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c("img", {
        staticClass: "team-logo",
        attrs: { src: _vm.src, alt: _vm.team_name },
        on: { error: _vm.set_default_logo }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }